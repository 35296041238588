.name {
  color: white;
  font-size: 20px;
  text-align: center;
  width: 100%;
}

.position {
  color: white;
  font-size: 16px;
  font-style: italic;
  text-align: center;
  width: 100%;
}

.avatar {
  width: 100px;
  height: 100px;
  margin: 0 0 20 0;
  font-size: 100px;
}

.fullWidth {
  width: 100%;
}

.container {
  position: relative;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.8);
  padding: 24px;
  height: 210px;
  min-height: 210px;
  max-height: 210px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }
}

.arrow {
  color: white;
  font-size: 32px;
  position: absolute;
  left: 12px;
  top: 12px;
}

.ArrowLeft {
  color: rgba(255, 255, 255, 1);
  font-size: 48px;
}

.ArrowRight {
  position: absolute;
  color: rgba(255, 255, 255, 1);
  font-size: 48px;
  right: 0;
}
