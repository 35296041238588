.Group {
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.8);
  border: none;
  padding: 32px;
  height: 210px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }
}
.ArrowLeft {
  color: rgba(255, 255, 255, 1);
  font-size: 48px;
}

.ArrowRight {
  position: absolute;
  color: rgba(255, 255, 255, 1);
  font-size: 48px;
  right: 0;
}

.post {
  width: 200px;
  background: rgba(0, 0, 0, 0.9);
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.8);
  position: fixed;
  bottom: 25px;
  right: 64px;
  border: none;
  height: 60px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }
}
