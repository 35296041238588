.card {
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.8);
  border: none;
}

.btn {
  background-color: #69140e !important;
  border-color: #510e0b !important;
  margin-top: 16px;
  height: 70px;
  font-size: 30px;
  color: white;
  &:hover {
    background-color: #772320 !important;
    border-color: #69140e !important;
    color: white;
  }
}
