.card {
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.8);
  border: none;
  padding: 32px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }
}
