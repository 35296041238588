@font-face {
  font-family: "RareWine";
  src:
    local("Metropolis-Regular"),
    url(../../fonts/Metropolis-Regular.woff) format("woff");
}

html,
body,
div {
  font-family: "RareWine" !important;
}

.App {
  text-align: center;
}

.list-item {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 32px;
  font-size: 46px;
}

.list-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

.header {
  font-size: 68px;
  text-align: center;
  color: white;
}
.subheader {
  color: white;
  font-style: italic;
  font-size: 28px;
  margin-top: -60px;
}

.bell {
  color: white;
  font-size: 48px;
  position: absolute;
  right: 24px;
  bottom: 24px;
}
.back {
  position: absolute;
  top: 24px;
  left: 24px;
}

.backIcon {
  font-size: 32px;
  color: white;
}

.backText {
  font-size: 32px;
  margin-left: 12px;
  color: white;
}

.notified {
  font-size: 40px;
  color: white;
}
