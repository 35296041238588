.avatar {
  width: 150px;
  height: 150px;
  margin: 0 0 20 0;
  font-size: 100px;
}

.card {
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.8);
  border: none;
}

.name {
  color: white;
  font-size: 32px;
  margin: 0;
}

.text {
  color: white;
  font-size: 25px;
  margin: 0;
  margin-top: 16;
  text-align: center;
}

.position {
  color: white;
  font-size: 18px;
  font-style: italic;
  margin: 0;
}

.btn {
  background-color: #69140e !important;
  border-color: #510e0b !important;
  margin-top: 16px;
  color: white;
  &:hover {
    background-color: #772320 !important;
    border-color: #69140e !important;
    color: white;
  }
}

.input {
  display: inline-block;
  position: relative;
  margin-top: 6px;
  margin-left: 10px;
  vertical-align: top;
  font-size: 20px;
  text-transform: uppercase;
}

.fakeInput {
  width: 100%;
  height: 40px;
  background-color: white;
  border-radius: 6px;
  display: inline-block;
  position: relative;
}

.cursor {
  width: 1px;
  background-color: rgba(0, 0, 0, 0.5);
  height: 50%;
  margin-top: 10px;
  animation: cursor infinite 1s;
  display: inline-block;
  position: relative;
}

.registrationLabel {
  color: white;
  font-size: 16px;
}

@keyframes cursor {
  0% {
    background-color: rgba(0, 0, 0, 0.5);
  }
  50% {
    background-color: transparent;
  }
}

.isPlateOkErrorText {
  color: red;
  font-size: large;
}
