.container {
  position: absolute;
  top: 24px;
  right: 4px;
}

.flag {
  width: 48px;
  margin-right: 20px;
  &:hover {
    cursor: pointer;
  }
  opacity: 0.2;
}

.active {
  opacity: 1;
}
