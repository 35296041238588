@import "~antd/dist/antd.css";
body {
  background-image: url("https://woodmartcdn-cec2.kxcdn.com/wp-content/uploads/2018/08/wine-slider-3-img.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #69140e !important;
  border-color: #510e0b !important;
}
.ant-checkbox-checked::after,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border: none !important;
}
